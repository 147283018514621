<template>
  <div class="packet-form">
    <v-container class="pb-0">
      <v-row class="py-5">
        <v-col
          cols="6"
          class="pl-0"
        >
          <div>
            <v-text-field
              v-model="packet.name"
              label="Nama Paket"
              dense
              type="text"
              outlined
              :rules="[validation.required]"
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="fPrice"
              label="Harga"
              dense
              type="text"
              outlined
              :rules="[validation.required]"
              onkeypress="return /[0-9]/i.test(event.key)"
            ></v-text-field>
          </div>
          <div>
            <v-autocomplete
              v-model="packet.level"
              :loading="isLoading"
              :items="levels"
              label="Jenjang"
              dense
              outlined
              item-text="name"
              :rules="[validation.required]"
              item-value="uuid"
            >
            </v-autocomplete>
          </div>
        </v-col>
        <v-col
          cols="6"
          class="pr-0"
        >
          <v-textarea
            v-model="packet.description"
            :rules="[validation.required]"
            label="Deskripsi"
            dense
            outlined
            height="175"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <div class="d-flex justify-center mb-1">
        <v-btn
          color="primary"
          outlined
          class="mx-2"
          large
          @click="closeHandler"
        >
          Batal
        </v-btn>
        <v-btn
          v-if="editUuid"
          color="primary"
          class="mx-2"
          large
          @click="update"
        >
          Selanjutnya
        </v-btn>
        <v-btn
          v-else
          color="primary"
          class="mx-2"
          large
          :disabled="!formValid"
          @click="add"
        >
          Selanjutnya
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'
import { integerValidator, required } from '@core/utils/validation'

export default {
  props: {
    editUuid: {
      type: String,
      default() {
        return ''
      },
    },
    resetForm: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      levels: [],
      packet: {
        uuid: '',
        name: '',
        price: '',
        total_library: null,
        level: '',
        level_name: '',
        description: '',
      },
      search: '',
      validation: {
        required,
        integerValidator,
      },
      formValid: false,
      isLoading: true,
    }
  },
  computed: {
    fPrice: {
      get() {
        const formatedPrice = toCurrencyFormat(this.packet.price)

        return formatedPrice === 'NaN' ? null : formatedPrice
      },
      set(newValue) {
        const formatedPrice = toCurrencyFormat(newValue)
        this.packet.price = formatedPrice === 'NaN' ? null : formatedPrice
      },
    },
  },
  watch: {
    editUuid() {
      if (this.editUuid) this.get()
    },
    resetForm() {
      if (this.resetForm) this.resetPacket()
    },
    packet: {
      handler() {
        const valid = []
        const requiredField = ['name', 'price', 'level']
        Object.entries(this.packet).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    'packet.level': {
      handler() {
        const level = this.levels.filter(item => item.uuid === this.packet.level)
        this.packet.level_name = level[0].name
      },
    },
  },
  mounted() {
    if (this.editUuid) this.get()
    this.getLevel()
  },
  methods: {
    resetPacket() {
      this.packet.name = ''
      this.packet.total_library = null
      this.packet.price = ''
      this.packet.level = ''
      this.packet.description = ''
    },
    async add() {
      this.formValid = true
      this.$emit('next-tab', this.packet)
    },
    async getLevel() {
      await this.$services.ApiServices.list('level', {}).then(
        ({ data }) => {
          this.levels = data.data
        },
        err => console.error(err),
      )
      this.isLoading = false
    },
    async get() {
      this.$services.ApiServices.get('packet', this.editUuid).then(
        ({ data }) => {
          this.packet = {
            uuid: data.data.uuid,
            name: data.data.name,
            price: data.data.price,
            level: data.data.level?.uuid,
            total_book: data.data.total_library,
            description: data.data.description,
          }
        },
        err => console.error(err),
      )
    },
    async update() {
      this.$emit('next-tab', this.packet)
    },
    closeHandler() {
      this.$emit('close-dialog')
      this.resetPacket()
    },
  },
}
</script>

<style>
</style>
